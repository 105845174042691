<template>
  <div>
    <TailwindComponentsLoading
      v-if="isLoading"
      fullScreen
    ></TailwindComponentsLoading>
    <BaseTableBlock class="pb-3">
      <template v-slot:row-1>
        <h1 class="app-title">
          {{ $t('Orchestrator Jobs') }}
        </h1>
        <div class="flex items-center">
          <BaseButton
            :label="$t('Refresh')"
            @click="getJobs"
            data-testid="refresh-button"
            icon="refresh"
          />
        </div>
      </template>
    </BaseTableBlock>
    <BaseTable
      :data="jobs"
      :columns="columns"
      :total="jobs.length"
      :sortOrderProp="sortOrder"
      :sortFieldProp="sortField"
      @sort="handleSort"
      v-if="!isLoading"
    >
      <template #docTitle-field="{ row }">
        <span
          class="truncate max-w-[140px] font-bold cursor-pointer hover:text-primary"
          @click="viewResult(row)"
          >{{ row.docTitle }}</span
        >
      </template>

      <template #started-field="{ row }">
        <BaseDate :value="row.started" showTime />
      </template>

      <!-- Status column with progress bar -->
      <template #state-field="{ row }">
        <div class="flex items-center">
          <span :class="stateTag(row.state)">{{ row.state }}</span>
        </div>
      </template>

      <template #action-field="{ row }">
        <div class="flex items-center space-x-2">
          <BaseButton
            v-if="canCancel(row)"
            :label="$t('Cancel')"
            @click="cancelJob(row)"
            small
            type="danger"
            icon="cancel"
          />
          <div v-if="row.state === 'FINISHED'" class="flex flex-col space-y-2">
            <BaseButton
              :label="$t('View')"
              @click="viewResult(row)"
              small
              icon="visibility"
            />
            <BaseButton
              v-if="canSaveToTranskribus"
              :label="$t('Save to Transkribus')"
              @click="saveToTranskribus(row)"
              small
              icon="save"
            />
          </div>
        </div>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import { useApi } from '~/composables/api/useApi'
import { useJobs } from '~/composables/api/useJobs'

export default {
  name: 'OrchestratorJobsTable',

  emits: [
    'jobs-loaded', // Emitted when jobs are loaded
    'notification', // Emitted for success/error notifications via $bus
  ],

  setup() {
    const jobsApi = useJobs()
    const runtimeConfig = useRuntimeConfig()
    const { fetchWithAuth } = useApi()

    return {
      jobsApi,
      runtimeConfig,
      fetchWithAuth,
    }
  },

  computed: {
    canSaveToTranskribus() {
      return this.$posthog.isFeatureEnabled('job-system-transkribus-integration')
    }
  },

  data() {
    return {
      jobs: [],
      isLoading: false,
      sortField: 'jobId',
      sortOrder: 'desc',
      localStatus: '',
      columns: [
        {
          field: 'docTitle',
          label: 'Title',
          display: true,
          sortable: true,
          custom: true,
          slotName: 'docTitle-field',
        },
        {
          field: 'jobImpl',
          label: 'Job Type',
          display: true,
          sortable: true,
        },
        {
          field: 'state',
          label: 'Status',
          display: true,
          sortable: true,
          custom: true,
          slotName: 'state-field',
        },
        {
          field: 'started',
          label: 'Started',
          display: true,
          sortable: true,
          custom: true,
          slotName: 'started-field',
        },
        {
          field: 'action',
          label: 'Actions',
          display: true,
          custom: true,
          slotName: 'action-field',
        },
      ],
    }
  },

  mounted() {
    this.getJobs()
  },

  methods: {
    async getJobs() {
      try {
        this.isLoading = true
        let data = await this.jobsApi.fetchJobsOrchestrator()

        if (!data || data.length === 0) {
          this.jobs = []
          this.$emit('jobs-loaded', [])
          return
        }

        // Map orchestrator data to table format
        this.jobs = data
          .filter(job => job.title && job.title.trim() !== '')
          .map(job => ({
            jobId: job.uuid,
            docTitle: job.title,
            jobImpl: 'Orchestrator',
            state: job.status,
            // TODO: set docId, started, ended, pages, job, collectionId, pageId as needed:
            docId: job.doc_id,
            created: job.created_at,
            started: job.started_at,
            ended: job.finished_at,
            pages: null,
            job: job,
            colId: job.collection_id,
            pageId: job.page_id,  
          }))

        // Emit jobs loaded event for parent components
        this.$emit('jobs-loaded', this.jobs)

        // Setup longpolling for jobs
        this.jobs.forEach(jobData => {
          let longpollLink = jobData.job.links?.find(
            link => link.title === 'Status Longpoll'
          )
          if (longpollLink) {
            this.startLongPolling(jobData)
          }
        })
      } catch (error) {
        console.error('Failed to fetch orchestrator jobs', error)
      } finally {
        this.isLoading = false
      }
    },

    handleSort({ field, order }) {
      this.sortField = field
      this.sortOrder = order

      this.jobs.sort((a, b) => {
        if (a[field] < b[field]) return order === 'asc' ? -1 : 1
        if (a[field] > b[field]) return order === 'asc' ? 1 : -1
        return 0
      })
    },

    async cancelJob(job) {
      try {
        await this.jobsApi.cancelJobOrchestrator(job.jobId)
        this.$bus.emit('notification', {
          id: Date.now(),
          type: 'success',
          message: this.$t('Job canceled'),
        })
      } catch (err) {
        this.$bus.emit('notification', {
          id: Date.now(),
          type: 'error',
          message: this.$t('Error canceling job'),
        })
        console.error('Failed to cancel job:', err)
      }
    },

    async viewResult(job) {
      try {
        this.navigateToDocument(job)
      } catch (err) {
        this.$bus.emit('notification', {
          id: Date.now(),
          type: 'error',
          message: this.$t('Failed to view result'),
        })
        console.error('Failed to view result:', err)
      }
    },
    
    async saveToTranskribus(row) {
      try {
        const response = await this.fetchWithAuth(
          `${this.runtimeConfig.public.REDIRECT_URI}/api/orchestrator/transkribus/${row.jobId}`,
          'POST',
          {
            data: {
              collectionId: row.colId,
              docId: row.docId,
              pageId: row.pageId
            }
          }
        )

        if (response.error) {
          throw new Error(response.error)
        }

        this.$bus.emit('notification', {
          id: Date.now(),
          type: 'success',
          message: this.$t('Successfully saved to Transkribus')
        })
      } catch (error) {
        console.error('Error saving to Transkribus:', error)
        this.$bus.emit('notification', {
          id: Date.now(),
          type: 'error',
          message: this.$t('Error saving to Transkribus')
        })
      }
    },

    navigateToDocument(item) {
      if (item.docId === -1) {
        return
      }

      // Use the global event bus
      this.$bus.emit('slideOverClose')

      const { $router } = useNuxtApp()
      const path = item.pages
        ? `/collection/${item.colId}/doc/${item.docId}/edit?pageid=${item.pages.split(/[, -]+/)[0]}`
        : `/collection/${item.colId}/doc/${item.docId}`

      $router.push(this.localePath(path))
    },

    startLongPolling(job) {
      if (!job?.jobId) return

      this.jobsApi.fetchJobOrchestratorLongpoll(job.jobId).then(
        response => {
          if (!response) return

          if (response.statusCode === 408) {
            this.startLongPolling(job)
            return
          }

          // Update job status if changed
          const jobIndex = this.jobs.findIndex(j => j.jobId === response.uuid)
          if (
            jobIndex !== -1 &&
            this.jobs[jobIndex].state !== response.status
          ) {
            this.jobs[jobIndex].state = response.status
            this.jobs[jobIndex].job = response
            this.$emit('jobs-loaded', this.jobs)
          }

          // Continue polling if link exists
          const longpollLink = response.links?.find(
            link => link.title === 'Status Longpoll'
          )
          if (longpollLink) {
            this.startLongPolling(job)
          }
        },
        error => {
          console.error('Long polling error:', error)
        }
      )
    },

    canCancel(job) {
      return ['CREATED', 'WAITING', 'RUNNING'].includes(job.state)
    },

    canViewResult(job) {
      return job.state === 'FINISHED'
    },

    stateTag(state) {
      switch (state) {
        case 'FINISHED':
          return [
            'inline-block',
            'px-3',
            'py-1',
            'text-xs',
            'font-semibold',
            'rounded-full',
            'text-successful-700',
            'bg-successful-100/80',
          ].join(' ')
        case 'FAILED':
          return [
            'inline-block',
            'px-3',
            'py-1',
            'text-xs',
            'font-semibold',
            'rounded-full',
            'text-danger',
            'bg-danger-100/80',
          ].join(' ')
        default:
          return [
            'inline-block',
            'px-3',
            'py-1',
            'text-xs',
            'font-semibold',
            'rounded-full',
            'text-[#193060]',
            'bg-[#193060]/[.06]',
          ].join(' ')
      }
    },

  },
}
</script>
